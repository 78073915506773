* {
    color: #FFFFFF;
    font-family: 'Poppins';
}

.privCont {
    padding: 80px !important;
}

/* p {
    margin-bottom: 10px;
    margin-top: 30px !important;
    font-size: larger;
}
*/
.mt-10 {
    margin-top: 10px !important;
}

strong {
    margin-top: 20px !important;
} 

.orangeFont {
    color: #FD6317;
}


.bottompage {
    padding-top: 18vh;
}

@media only screen and (max-width: 800px) { 
    * {
        color: #FFFFFF;
        font-family: 'Poppins';
    
    }
    
    .privCont {
        padding: 20px !important;
    }
    

    p {
        margin-bottom: 10px;
        margin-top: 30px !important;
        font-size: 12px !important;
    }

}