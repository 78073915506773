.homediv {
  background-color: #16191e;
  background-size: contain;
  margin: 0px;
  height: auto;
  width: 100%;
}

.homeTopContainer {
  position: relative;
  display: flex;
  max-width: 100vw;
  min-height: 350px;
  height: auto;
  margin-top: 50px;
  margin-left: 8vw;
}

.homeheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 62px;
  line-height: 93px;
  color: #ffffff;
}

.homeimage {
  position: absolute;
  height: auto;
  width: auto;
  right: 9.5vw;
  top: 85px;
  margin-left: 9vw;
}

.homeRightImg {
  height: auto;
  width: auto;
}

.homeMiddleContainer {
  width: 40%;
  margin-left: 8vw;
}

.homeMiddleHeading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 275;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
  margin-top: 44px;
}

.homeBottomContainer {
  display: flex;
  flex-direction: row;
  margin-left: 2vw;
}

.storeCont {
  background: #272727;
  border: 1px solid #434b50;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.14);
  border-radius: 16px;
  width: 180px;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  padding-top: 35px;
}

a {
  text-decoration: none !important;
}

.extLinkIcon {
  text-decoration: none !important;
}

.storeCont:hover {
  background: #8c8c8c;
}

.storeimage {
  width: 38px;
  height: 46px;
  /* margin-top: 10px; */
}

.storeText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 275;
  font-size: 16px;
  /* line-height: 24px; */
  text-align: center;
  color: #ffffff;
  margin-top: 30px;
}

.storeHeaderText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  /* line-height: 27px; */
  text-align: center;
  color: #ffffff;
}

.homeLowerContainer {
  color: white;
  font-family: "Poppins";
  font-size: 24px;
  font-weight: normal;
  text-align: center;
  /* margin-left: 6.5vw; */
  margin-top: 106px;
}

.homeLowerContainer2 {
  color: white;
  font-family: "Poppins";
  font-size: 32px;
  font-weight: bold;
}

.homeLowerContainer4 {
  margin-left: 6.5vw;
  margin-top: 10vh;
}

.contactUsContainer {
  margin-left: 6.5vw;
  margin-top: 250px;
  margin-bottom: 142px;
}

.homeLowerContainer5 {
  margin-left: 6.5vw;
  margin-top: 30vh;
}

.itemsContainerBottom {
  width: 100%;
  height: auto;
  /* background-color: #1F2228; */
  border-radius: 5px;
  margin-top: 7.5vh;
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  flex-flow: wrap;
}

.bottomContainer {
  width: 400px;
  min-height: 400px;
  height: auto;
  padding: 38.5px 14px 38.5px 16px;
  border-radius: 10px;
  border: 0.5px solid #656565;
  /* background-image: linear-gradient(152deg,hsla(0,0%,100%,.4),hsla(0,0%,100%,.05)); */
  background-color: #1f2228;
  margin: 60px 0px 20px 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.itemHeader {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 36px;
  /* identical to box height */
  text-align: center;
  color: #ffffff;
  margin-top: 15px;
  margin-bottom: 10px;
}

.itemDescription {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #8e97a0;
  margin-top: 15px;
  margin-bottom: 10px;
}

.bottomContainer1 {
  max-width: 500px;
  margin-left: calc(50vw - 250px);
  color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  margin-top: 30vh;
}

.bottomContainer2 {
  color: #ffffff;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
}

.colorOrange {
  color: #fd6317;
}

.colorOrange1 {
  width: 142px;
  height: 48px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #fd6317;
}

.colorOrange2 {
  width: 142px;
  height: 48px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #fd6317;
}

.homeAchievements {
  display: flex;
  justify-content: center;
  /* margin-left: 6.5vw; */
  padding-bottom: 36px;
}

.bottombanner {
  display: flex;
  background: #1f2228;
  width: 100%;
  height: 350px;
  flex-direction: row;
  padding-bottom: 36px;
  margin-top: 250px;
}

.bottombannerleft {
  width: 50%;
  margin-left: 6.5vw;
  padding-top: 50px;
}

.bottomleftheading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #fd6317;
}

.bottomleftcontent {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  text-align: justify !important;
  text-justify: inter-word;
}

.bottombannerright {
  float: right;
  align-items: center;
  padding-left: 10vw;
  padding-top: 4vh;
}

.onlyMob {
  display: none;
}

.strikeText {
  text-decoration-line: line-through !important;
  text-decoration-color: #fd6317 !important;
  text-decoration-style: solid !important;
  text-decoration-thickness: 2px !important;
  /* -webkit-text-decoration: line-through #fd6317 2px; */
  -webkit-text-decoration-line: line-through !important;
  -webkit-text-decoration-color: #fd6317 !important;
  -webkit-text-decoration-style: solid !important;
  -webkit-text-decoration-thickness: 2px !important;
}

/* .scrollTopCont {
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

@media only screen and (max-width: 600px) {
  .homeTopContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    /* min-height: 600px; */
    height: auto;
    margin-top: 20px;
    margin-left: 6.5vw;
  }

  .homeheading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: #ffffff;
  }

  .onlyWeb {
    display: none;
  }

  .onlyMob {
    display: inline-block;
  }

  .homeimage {
    position: absolute;
    height: 165px;
    width: 218px;
    left: 0px;
    top: 185px;
    margin-left: calc(43.5vw - 109px);
  }

  .homeMiddleContainer {
    margin-top: 10px;
    width: 90%;
  }

  .homeMiddleHeading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 275;
    font-size: 18px;
    line-height: 27px;
  }

  .homeLowerContainer {
    color: white;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 250px !important;
    text-align: center;
  }

  .homeLowerContainer2 {
    color: white;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }
  .bottombanner {
    display: flex;
    background: #1f2228;
    width: 100%;
    height: auto;
    flex-direction: column;
    padding-bottom: 86px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 50px;
  }
  .bottombannerleft {
    width: 100%;
    margin-left: 0vw;
    padding-top: 9vh;
  }
  .bottombannerright {
    float: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0vw;
    padding-top: 4vh;
    margin-bottom: 20px;
  }
  .bottomleftheading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
  .bottomleftcontent {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }
  .homeRightImg {
    width: 191px;
    height: 159px;
  }
  .storeCont {
    width: 40vw !important;
    height: 40vw !important;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
  }
  .storeimage {
    width: 34.41px;
    height: 42.56px;
    margin-top: 10px !important;
  }

  .storeText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 275;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    margin-top: 0px;
  }

  .storeHeaderText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
  .bottomContainer1 {
    max-width: 100vw;
    margin-left: 0;
    text-align: center !important;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1100px) and (orientation: landscape) {
  .homeheading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 66px;
    text-align: left;
    color: #ffffff;
    min-width: 50vw;
  }

  .homeimage {
    position: absolute;
    height: 284px;
    width: 360px;
    right: 9.5vw;
    top: 35px;
    margin-left: 9vw;
  }

  .bottombanner {
    min-height: 430px;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1100px) and (orientation: portrait) {
  .homeTopContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    max-width: 100vw;
    /* min-height: 600px; */
    height: auto;
    margin-top: 20px;
    margin-left: 6.5vw;
  }

  .homeheading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 56px;
    text-align: center;
    color: #ffffff;
    margin-top: 20px;
  }

  .onlyWeb {
    display: none;
  }

  .onlyMob {
    display: inline-block;
  }

  .homeimage {
    position: absolute;
    height: 196px;
    width: 260px;
    left: 0px;
    top: 105px;
    margin-left: calc(50vw + 10px);
  }

  .homeMiddleContainer {
    margin-top: 10px;
    width: 90%;
  }

  .homeMiddleHeading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 275;
    font-size: 18px;
    line-height: 27px;
  }

  .homeLowerContainer {
    color: white;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 250px !important;
    text-align: center;
  }

  .homeLowerContainer2 {
    color: white;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }
  .bottombanner {
    display: flex;
    background: #1f2228;
    width: 100%;
    height: auto;
    flex-direction: column;
    padding-bottom: 86px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 50px;
  }
  .bottombannerleft {
    width: 100%;
    margin-left: 0vw;
    padding-top: 9vh;
  }
  .bottombannerright {
    float: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0vw;
    padding-top: 4vh;
    margin-bottom: 20px;
  }
  .bottomleftheading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
  .bottomleftcontent {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
  .homeRightImg {
    width: 191px;
    height: 159px;
  }
  .storeCont {
    max-width: 30vw !important;
    max-height: 30vw !important;
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  .storeimage {
    width: 54.41px;
    height: 62.56px;
    margin-top: 0px !important;
  }

  .storeText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 275;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #ffffff;
    margin-top: 0px;
  }

  .storeHeaderText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
  .bottomContainer1 {
    max-width: 100vw;
    margin-left: 0;
    text-align: center !important;
  }
}

@media only screen and (min-width: 1700px) {
  html {
    font-size: 36px;
  }

  .homeTopContainer {
    position: relative;
    display: flex;
    max-width: 100vw;
    min-height: 350px;
    height: auto;
    margin-top: 3rem;
    margin-left: 8vw;
  }

  .homeheading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 3rem;
    color: #ffffff;
  }

  .homeimage {
    position: absolute;
    height: 20rem;
    width: 20rem;
    right: 9.5vw;
    top: 85px;
    margin-left: 9vw;
  }

  .homeRightImg {
    height: auto;
    width: auto;
  }

  .homeMiddleContainer {
    width: 40%;
    margin-left: 8vw;
  }

  .homeMiddleHeading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 275;
    font-size: 1rem !important;
    line-height: 1.5rem !important;
    color: #ffffff;
    margin-top: 44px;
  }

  .homeBottomContainer {
    display: flex;
    flex-direction: row;
    margin-left: 2vw;
  }

  .storeCont {
    background: #272727;
    border: 1px solid #434b50;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.14);
    border-radius: 16px;
    min-width: 7rem !important;
    min-height: 7rem !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 1rem !important;
    padding-top: 2rem !important;
  }

  .storeCont:hover {
    background: #8c8c8c;
  }

  .storeimage {
    min-width: 2.1rem !important;
    min-height: 2.5rem !important;
    /* margin-top: 10px; */
  }

  .storeText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 275;
    font-size: 0.65rem !important;
    /* line-height: 3rem; */
    text-align: center;
    color: #ffffff;
    margin-top: 0px !important;
  }

  .storeHeaderText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 0.7rem !important;
    /* line-height: 3rem; */
    text-align: center;
    color: #ffffff;
  }

  .homeLowerContainer {
    color: white;
    font-family: "Poppins";
    font-size: 1.5rem;
    font-weight: normal;
    text-align: center;
    /* margin-left: 6.5vw; */
    margin-top: 4.5rem;
  }

  .colorOrange {
    font-size: 1.5rem !important;
  }

  .homeLowerContainer2 {
    color: white;
    font-family: "Poppins";
    font-size: 1.5rem;
    font-weight: bold;
  }

  .homeLowerContainer4 {
    margin-left: 6.5vw;
    margin-top: 10vh;
  }

  .contactUsContainer {
    margin-left: 6.5vw;
    margin-top: 250px;
    margin-bottom: 142px;
  }

  .homeLowerContainer5 {
    margin-left: 6.5vw;
    margin-top: 30vh;
  }

  .itemsContainerBottom {
    width: 100%;
    height: auto;
    /* background-color: #1F2228; */
    border-radius: 5px;
    margin-top: 7.5vh;
    display: flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
    flex-flow: wrap;
  }

  .bottomContainer1 {
    max-width: 500px;
    margin-left: calc(50vw - 250px);
    color: #ffffff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.15rem !important;
    line-height: 1.3rem !important;
    text-align: center;
    margin-top: 30vh;
  }

  .bottomContainer2 {
    color: #ffffff;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem !important;
    line-height: 1.4rem !important;
  }

  .bottombanner {
    display: flex;
    background: #1f2228;
    width: 100%;
    height: 750px;
    flex-direction: row;
    padding-bottom: 76px;
    margin-top: 450px;
  }
  
  .bottombannerleft {
    width: 50%;
    margin-left: 6.5vw;
    padding-top: 120px;
  }
  
  .bottomleftheading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 1.35rem !important;
    line-height: 1.6rem !important;
    color: #fd6317;
  }
  
  .bottomleftcontent {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 0.73rem !important;
    line-height: 1.2rem !important;
    color: #ffffff;
    text-align: justify !important;
    text-justify: inter-word;
  }
  
  .bottombannerright {
    float: right;
    align-items: center;
    padding-left: 10vw;
    padding-top: 120px;
  }

  .homeRightImg {
    min-width: 480px;
    min-height: 540px;
  }

  .homeContent {
    margin-top: 200px !important;
  }

  .homeLowerContainer {
    margin-top: 250px !important;
  }
}
