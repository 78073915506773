.packagesContainerBottom {
    max-width: 100vw;
    height: auto;
    /* background-color: #1F2228; */
    border-radius: 5px;
    /* margin-top: 1.5vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.packageType {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 15px;
    margin-bottom: 10px;
}

.packageAmount {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #8E97A0;
    margin-top: 15px;
    margin-bottom: 10px;
}


.carouselWeb {
    display: block;
}
    
.carouselMobile {
    display: none;
}

.carouselDesktop {
    display: none;
}

/* 
.carouselItem {
    min-width: "763px";
    padding-left: "90px";
    padding-right: "90px";
    padding-top: "50px";
    padding-bottom: "50px";
    box-shadow: "0px 4px 4px rgba(0, 0, 0, 0.25)";
    display: "flex";
    flex-direction: "column";
    align-items: "center";
} */

.carouselImage {
    transition: transform .2s;
}

.carouselImage:hover {
    transform: scale(1.7);
}

@media only screen and (max-width: 600px) {

    /* .carouselItem {
        min-width: "100vw";
        min-height: "600px";
        padding-left: "30px";
        padding-right: "30px";
        padding-top: "50px";
        padding-bottom: "50px";
        box-shadow: "0px 4px 4px rgba(0, 0, 0, 0.25)";
        display: "flex";
        flex-direction: "column";
        align-items: "center";
    } */
    .packageType {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: #FFFFFF;
    }

    .packageAmount {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #8E97A0;
    }


    .carouselMobile {
        display: block;
    }

    
    .carouselWeb {
        display: none;
    }

}

@media only screen and (min-width: 1900px) { 
    html { font-size: 36px; }

    
    .packagesContainerBottom {
        max-width: auto;
        height: auto;
        /* background-color: #1F2228; */
        border-radius: 5px;
        /* margin-top: 1.5vh; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
    }
    
    .packageType {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 0.6rem !important;
        line-height: 1.0rem !important;
        text-align: center;
        color: #FFFFFF;
        margin-top: 15px;
        margin-bottom: 10px;
    }
    
    .packageAmount {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 0.8rem !important;
        line-height: 1.2rem !important;
        text-align: center;
        color: #8E97A0;
        margin-top: 15px;
        margin-bottom: 10px;
    }


    .carouselWeb {
        display: none !important;
    }

    .carouselMobile {
        display: none;
    }


    .carouselDesktop {
        display: block;
    }


}