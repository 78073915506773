.packagesContainerBottom{
    width: 100%;
    height: auto;
    /* background-color: #1F2228; */
    border-radius: 5px;
    /* margin-top: 1.5vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.packageType{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 36px;
    /* identical to box height */
    text-align: center;
    color: #FFFFFF;
    margin-top: 15px;
    margin-bottom: 10px;
}
.packageAmount{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #8E97A0;
    margin-top: 15px;
    margin-bottom: 10px;
}

.carouselContainer {
    /* width: 100vw; */
    /* overflow: hidden !important; */
    margin-bottom: 30px !important;
    /* margin-left: 14vw; */
    /* margin-left: auto; */
    padding-bottom: 10px !important;
}

.itemContainer {
    min-width: 763px;
    /* margin-left: 10vw; */
}

.carouselParent {
    min-width: 100vw;
    overflow: hidden !important;
    position: relative;
    height: 800px;
    padding-left: calc(43.5vw - 380px);
    padding-bottom: 10px !important;
}

.dotList {
   position: absolute;
   left: 0vw;
   bottom: -10px;
   margin-top: 20px !important;
   margin-bottom: 10px !important;
}

@media only screen and (max-width: 600px) {
    .carouselContainerHome {
        display: none !important;
    }

    .bottomContainer {
        display: none;
    }

    .packageType {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: #FFFFFF;
    }

    .packageAmount {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #8E97A0;
    }

    .mobileCarousel {
        display: block;
    }
}