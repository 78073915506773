.contactUsDiv {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-width: 90vw;
    margin-top: 5vh;
 }
 
 .tryDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5vh;
 }
 
 .contactUsBoxDiv{
     box-sizing: border-box;
     width: 40vw;
     height: 519px;
     margin-bottom: 10vh;
     margin-left: 10vw;
     background-color: #1F2228;
     box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
     backdrop-filter: blur(44px);
     border-radius: 25px;
     padding-top: 5vh;
     display: flex;
     flex-direction: column;
     position: relative;
     padding-left: 42px;
     padding-right: 42px;
     padding-top: 49px;
     padding-bottom: 46px;
 }
 .contactUsTitle{
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 700;
     font-size: 18px;
     line-height: 36px;
     text-align: left;
     color: #FFFFFF;
 }
 .contactLine{
     width: 189px;
     height: 36px;
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 400;
     font-size: 24px;
     line-height: 36px;
     color: #FFFFFF;
 }
 .contactLine2{
     width: 142px;
     height: 48px;  
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 700;
     font-size: 32px;
     line-height: 48px;
     color: #FFFFFF;
 }
 .colorOrange {
     width: 142px;
     height: 48px;  
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 700;
     font-size: 32px;
     line-height: 48px;
     color: #FD6317;
 }

 .homeMiddleHeading {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 275;
    font-size: 24px;
    line-height: 36px; 
    color: #FFFFFF;
    margin-top: 44px;
}

.homeBottomContainerContact {
  display: flex;
  flex-direction: row;
}

 
.storeCont {
    background: #272727;
    border: 1px solid #434B50;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.14);
    border-radius: 16px;
    width: 180px;
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    padding-top: 35px;
}

.storeimage {
    width: 38px;
    height: 46px;
    /* margin-top: 10px; */
}

.storeText {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 275;
    font-size: 16px;
    /* line-height: 24px; */
    text-align: center;
    color: #ffffff;
    margin-top: 30px;
}

.storeHeaderText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    /* line-height: 27px; */
    text-align: center;
    color: #FFFFFF;
}



 .downloadHeading{
     width: 376px;
     height: 36px;
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 275;
     font-size: 24px;
     line-height: 36px;
     color: #FFFFFF;
     margin-top: 5vh;
 }
 .downloadButtons {
     width: 180px;
     height: 180px;
     display: flex;
     margin-top: 2vh;
 }
 
 .contactSubmitButton{
     width: 139px;
     height: 52px;
     font-family: 'Poppins';
     font-style: normal;
     font-weight: 700;
     font-size: 16px;
     line-height: 24px;
     text-align: center;
     color: #FFFFFF;
     background: #FD6317;
 }


/*  
.scrollTopContBusiness > .scroll-to-top {
    background-color: #fd6317;
    border-radius: 3 !important;
    box-shadow: none !important;
} */

 @media only screen and (max-width: 600px) {  
    .contactUsDiv {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        max-width: 90vw;
        margin-top: 50px;
     }
     
     .tryDiv {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 5vh;
        min-width: 90vw;
     }
     
     .contactUsBoxDiv{
         box-sizing: border-box;
         min-width: 90vw;
         height: 519px;
         margin-bottom: 10vh;
         margin-left: 0vw;
         background-color: #1F2228;
         box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
         backdrop-filter: blur(44px);
         border-radius: 25px;
         padding-top: 5vh;
         display: flex;
         flex-direction: column;
         position: relative;
         padding-left: 42px;
         padding-right: 42px;
         padding-top: 49px;
         padding-bottom: 46px;
     }
     .storeCont { 
        width: 40vw !important;
        height: 40vw !important;
        padding-bottom: 10px !important;
        padding-top: 10px !important;
    }
    .storeimage {
        width: 34.41px;
        height: 42.56px;
        margin-top: 10px !important;
    }
    
    .storeText {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 275;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        margin-top: 10px;
    }
    
    .storeHeaderText {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
    }
 }

 @media only screen and (min-device-width: 600px) and (max-device-width: 1100px) and (orientation: landscape) { 



 }

 @media only screen and (min-device-width: 600px) and (max-device-width: 1100px) and (orientation: portrait) { 
    .contactUsDiv {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-evenly;
        max-width: 90vw;
        margin-top: 50px;
     }
     
     .tryDiv {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 5vh;
        min-width: 90vw;
     }
     
     .contactUsBoxDiv{
         box-sizing: border-box;
         min-width: 90vw;
         height: 519px;
         margin-bottom: 10vh;
         margin-left: 0vw;
         background-color: #1F2228;
         box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
         backdrop-filter: blur(44px);
         border-radius: 25px;
         padding-top: 5vh;
         display: flex;
         flex-direction: column;
         position: relative;
         padding-left: 42px;
         padding-right: 42px;
         padding-top: 49px;
         padding-bottom: 46px;
     }
     .storeCont { 
        width: 40vw !important;
        height: 40vw !important;
        padding-bottom: 10px !important;
        padding-top: 10px !important;
    }
    .storeimage {
        width: 34.41px;
        height: 42.56px;
        margin-top: 10px !important;
    }
    
    .storeText {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 275;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #ffffff;
        margin-top: 10px;
    }
    
    .storeHeaderText {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #FFFFFF;
    }

 }

 @media only screen and (min-width: 1700px) { 
    html { font-size: 36px; }

    .contactLine{
        width: 689px;
        height: 36px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 1.5rem !important;
        line-height: 1.9rem !important;
        color: #FFFFFF;
    }

    .contactLine2{
        width: 142px;
        height: 48px;  
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 48px;
        color: #FFFFFF;
        margin-top: 1rem !important;
    }

    .contactUsBoxDiv{
        box-sizing: border-box;
        width: 40vw;
        height: 819px !important;
        margin-bottom: 10vh;
        margin-left: 10vw;
        background-color: #1F2228;
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(44px);
        border-radius: 25px;
        padding-top: 5vh;
        display: flex;
        flex-direction: column;
        position: relative;
        padding-left: 82px !important;
        padding-right: 82px !important;
        padding-top: 89px !important;
        padding-bottom: 86px !important;
    }

    .contactUsTitle{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 1rem !important;
        line-height: 1.5rem !important;
        text-align: left;
        color: #FFFFFF;
    }

    .contactSubmitButton{
        width: 139px;
        height: 82px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 700;
        font-size: 0.75rem !important;
        line-height: 1.2rem !important;
        text-align: center;
        color: #FFFFFF;
        background: #FD6317;
    }
    .contactUsBoxDiv > .MuiTextField-root > .MuiInputLabel-root {
        font-size: 0.8rem !important;
        line-height: 1.2rem !important;
     }

     .contactUsBoxDiv > .MuiTextField-root > .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
        font-size: 0.8rem !important;
        line-height: 1.2rem !important;
     }
}