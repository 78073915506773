.bottomBar {
    background-color: black;
    color: azure;
    position: absolute;
    width: 100%;
    min-height: 300px;
    left: 0px;
    padding-top: 30px;
}

.bottomSocial {
    padding-top: 25px;
    display: flex;
    justify-content: center;
    gap: 40px;
}

.contactDetails {
    padding-top: 20px;
    display: flex;
    justify-content: center;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;

    color: #D1D1D1;
}

.hrLine {
    display: flex;
    justify-content: center;
}

.lineWidth {
    width: 1160px;
    opacity: 0.2;
    border: 1px solid #FFFFFF;
}

.cdCapital {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    text-transform: capitalize;
}

.copyrightLine {
    display: flex;
    justify-content: center;
    margin-top: 12px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 13px;
    display: flex;
    align-items: center;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: #D1D1D1;
}

.extLinkIcon {
    color: azure !important;
    text-decoration: none; 
    cursor: pointer; 
}


@media only screen and (max-width: 600px) {
    .bottomBar {
        height: 414px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 50px !important;
    }

    .lineWidth {
        width: 60vw;
    }

    .contactDetails {
        font-weight: 300;
        font-size: 14px;
        line-height: 160%;
        width: 70vw;
    }

    .copyrightLine {
        font-weight: 300;
        font-size: 12px;
        letter-spacing: 0.05em;
        width: 70vw;
        text-align: center;
    }

    .copyrightText {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 110%;
        text-align: center;
    }
}

@media only screen and (min-width: 1700px) {
    html {
      font-size: 36px;
    }

    .bottomBar {
        background-color: black;
        color: azure;
        position: absolute;
        width: 100%;
        height: 400px !important;
        left: 0px;
    }

    .bottomSocial {
        padding-top: 65px !important;
        display: flex;
        justify-content: center;
        gap: 80px !important;
    }
    
    .contactDetails {
        padding-top: 20px;
        display: flex;
        justify-content: center;
    
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 0.65rem !important;
        line-height: 0.9rem !important;
    
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.05em;
    
        color: #D1D1D1;
    }
    
    .hrLine {
        display: flex;
        justify-content: center;
    }
    
    .lineWidth {
        width: 1560px;
        opacity: 0.2;
        border: 1px solid #FFFFFF;
    }
    
    .cdCapital {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 0.65rem !important;
        line-height: 0.9rem !important;
        text-transform: capitalize;
    }
    
    .copyrightLine {
        display: flex;
        justify-content: center;
        margin-top: 12px;
    
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 300;
        font-size: 0.65rem !important;
        line-height: 0.9rem !important;
        display: flex;
        align-items: center;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        color: #D1D1D1;
    }
    
    .extLinkIcon {
        color: azure !important;
        text-decoration: none; 
        cursor: pointer; 
    }
    
}