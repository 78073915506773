.advisorsMain {
  display: flex;
  margin-left: 7vw;
  padding-top: 7vh;
}

.advisorsLine {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.advisorsLine2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #ffffff;
}

.cardDiv {
  background: #16191e;
  max-height: 379px !important;
  min-width: 303px !important;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px !important;
}

.cardDiv.card {
  background: #16191e;
  margin-bottom: 10px !important;
}

.cardItem {
  width: 278px;
  height: 276px;
  background-color: #16191e;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 11px;
}

.cardDetails {
  background: #16191e;
  width: 100% !important;
}

.card-img {
  max-width: 256px !important;
  max-height: 209px !important;
}

.cardDetails-img {
  max-width: 256px !important;
  max-height: 209px !important;
}

.cardName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #ffffff !important;
  min-width: "100%";
}

.cardDesignationAdvisors {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #fd6317;
  color: #ffffff;
}

.cardDescriptionAdvisors {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #fd6317;
  /* color: #FFFFFF; */
  margin-top: -10px;
}

.contactUsContainer {
  margin-left: 5.5vw;
  margin-top: 250px;
  margin-bottom: 142px;
}

@media only screen and (max-width: 600px) {
  .displayWeb {
    flex-direction: column !important;
    align-items: center !important;
  }
  .aboutUsContent {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
  }

  .aboutUsLine {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
  }

  .cardDiv.card {
    margin-top: 50px !important;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1100px) and (orientation: portrait) {
  .displayWeb {
    flex-direction: column !important;
    align-items: center !important;
  }
  .aboutUsContent {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
  }

  .aboutUsLine {
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
  }

  .cardDiv.card {
    margin-top: 50px !important;
  }
}

@media only screen and (min-width: 1900px) {
  html {
    font-size: 36px;
  }

  .advisorsLine {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem !important;
    line-height: 1.8rem !important;
    color: #ffffff;
  }

  .advisorsLine2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem !important;
    line-height: 1.8rem !important;
    color: #ffffff;
  }

  .displayWeb > .col-xl-3 {
    min-width: 11rem !important;
    min-height: 600px !important;
  }

  .cardDiv {
    background: #16191e;
    max-height: 379px !important;
    min-width: 303px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 10px !important;
  }

  .cardDiv.card {
    background: #16191e;
    margin-bottom: 10px !important;
  }

  .cardItem {
    width: 278px;
    height: 276px;
    background-color: #16191e;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 11px;
    min-width: 11rem !important;
  }

  .cardDetails {
    background: #16191e;
    width: 100% !important;
  }

  .card-img {
    max-width: 256px !important;
    max-height: 209px !important;
  }

  .cardDetails-img {
    min-width: 356px !important;
    min-height: 309px !important;
  }

  .cardName {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 0.7rem !important;
    line-height: 1.2rem !important;
    text-align: center;
    color: #ffffff !important;
    min-width: 8.2rem !important;
  }

  .cardDesignationAdvisors {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem !important;
    line-height: 1.2rem !important;
    text-align: center;
    color: #fd6317;
    color: #ffffff;
  }

  .cardDescriptionAdvisors {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 0.7rem !important;
    line-height: 1.2rem !important;
    text-align: center;
    color: #fd6317;
    /* color: #FFFFFF; */
    margin-top: -10px;
  }
}
