.busDiv {
  height: auto;
}

.businessContent {
  display: flex;
  position: relative;
  width: 100%;
  height: 685px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 62px;
  line-height: 93px;
  color: #ffffff;
}

.businessContainer {
  width: 50%;
  height: 100%;
  float: left;
}

.rightImgdiv {
  /* position: relative; */
  float: right;
  position: absolute !important;
  bottom: 0px;
  right: 0px;
}

.rightImg {
  height: 494px;
  width: 716px;
}

.businessHeading {
  width: 520px;
  height: auto;
  flex-flow: wrap;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 62px;
  line-height: 93px;
  color: #ffffff;
  margin-left: 8vw;
  margin-top: 12vh;
}

.requestButtonContent {
  width: 180px;
  height: 52px;
  margin-left: 8vw;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  background: #fd6317;
  border-radius: 10px;
}

.features {
  margin-top: 30vh;
  background-color: #16191e;
}

.bottom {
  display: flex;
}

.businessContent2 {
  display: flex;
  width: 100%;
  /* margin-left: 8vw; */
  padding-top: 40px;
  margin-top: 150px;
  flex-direction: column;
  text-align: center;
}

.bc2Heading {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 275;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.bc2Heading2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #ffffff;
}

.bottomDemo {
  display: flex;
  background: #1f2228;
  width: 100%;
  height: 348px;
  padding-top: 12vh;
  flex-direction: column;
  margin-top: 18vh;
  position: relative;
}

.bottomDemoHeading {
  text-align: center;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #ffffff;
  position: absolute;
  width: 800px;
  left: calc(50vw - 400px);
  top: 110px !important;
}

.requestButton {
  width: 180px;
  height: 52px;
  margin-left: 42vw;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  background: #fd6317;
  border-radius: 10px;
  position: absolute;
  top: 200px !important;
}

.bottompage {
  padding-top: 18vh;
}

.requestButtonMobile {
  display: none;
}

.onlyMob {
  display: none;
}

.onlyIpad {
  display: none;
}

@media only screen and (max-width: 600px) {
  .businessContent {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: 570px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 62px;
    line-height: 93px;
    color: #ffffff;
  }

  .businessContainer {
    width: 100%;
    height: 100%;
    float: left;
    /* margin-left: 5vw; */
  }

  .rightImgdiv {
    /* position: relative; */
    /* float: left; */
    position: absolute !important;
    bottom: 90px;
    right: 0px;
  }

  .rightImg {
    width: 322px;
    height: 222px;
  }

  .businessHeading {
    max-width: 80vw;
    height: auto;
    flex-flow: wrap;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
    margin-left: 10vw;
    margin-top: 50px;
  }
  .requestButtonContent {
    display: none;
  }

  .requestButtonMobile {
    display: inline-block;
    width: 180px;
    height: 52px;
    margin-left: calc(50vw - 90px);
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    background: #fd6317;
    border-radius: 10px;
  }
  .bottomDemo {
    display: flex;
    background: #1f2228;
    width: 100%;
    height: 348px;
    padding-top: 80px;
    padding-bottom: 80px;
    flex-direction: column;
    margin-top: 50px;
    max-width: 100vw;
  }

  .bottomDemoHeading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
    max-width: 300px;
    left: calc(50vw - 150px);
  }

  .requestButtonBox {
    width: 100vw;
    padding-left: calc(50vw - 90px);
    margin-top: 30px;
  }
  .requestButton {
    margin-left: 0px;
  }
  .onlyMob {
    display: inline-block;
  }
  .bc2Heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .bc2Heading2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
    max-width: 80vw;
    text-align: center;
    margin-left: 7vw;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1100px) and (orientation: landscape) {
  .rightImgdiv {
    /* position: relative; */
    float: right;
    position: absolute !important;
    bottom: 120px;
    right: 0px;
  }

  .rightImg {
    height: 397px;
    width: 500px;
  }

  .businessHeading {
    width: 520px;
    height: auto;
    flex-flow: wrap;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 66px;
    color: #ffffff;
    margin-left: 6.5vw;
    margin-top: 12vh;
  }

  .onlyIpad {
    display: inline-block;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1100px) and (orientation: portrait) {
  .businessContent {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    padding-top: 100px !important;
    padding-bottom: 100px !important;
    position: relative;
    width: 100%;
    height: 570px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 62px;
    line-height: 93px;
    color: #ffffff;
    margin-left: 50px !important;
  }

  .businessContainer {
    width: 100%;
    height: 100%;
    float: left;
    /* margin-left: 5vw; */
  }

  .rightImgdiv {
    /* position: relative; */
    /* float: left; */
    position: absolute !important;
    bottom: 110px;
    right: 50px;
  }

  .rightImg {
    width: 322px;
    height: 222px;
  }

  .businessHeading {
    width: 45vw;
    height: auto;
    flex-flow: wrap;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 48px;
    text-align: center;
    color: #ffffff;
    margin-left: 10px;
    margin-top: 20px;
  }
  .requestButtonContent {
    display: none;
  }

  .requestButtonMobile {
    display: inline-block;
    width: 180px;
    height: 52px;
    margin-left: 6.2vw;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    background: #fd6317;
    border-radius: 10px;
  }
  .bottomDemo {
    display: flex;
    background: #1f2228;
    width: 100%;
    height: 348px;
    padding-top: 80px;
    padding-bottom: 80px;
    flex-direction: column;
    margin-top: 50px;
  }

  .bottomDemoHeading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #ffffff;
  }

  .requestButtonBox {
    width: 100vw;
    padding-left: calc(50vw - 90px);
    margin-top: 30px;
  }
  .requestButton {
    margin-left: 0px;
  }
  .onlyMob {
    display: inline-block;
  }
  .bc2Heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .bc2Heading2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
    max-width: 80vw;
    text-align: center;
    margin-left: 7vw;
  }
}

@media only screen and (min-width: 1700px) {
  html {
    font-size: 36px;
  }

  .businessContent {
    display: flex;
    position: relative;
    width: 100%;
    height: 985px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 112px !important;
    line-height: 143px !important;
    color: #ffffff;
  }

  .businessContainer {
    width: 50%;
    height: 100%;
    float: left;
  }

  .rightImgdiv {
    /* position: relative; */
    float: right;
    position: absolute !important;
    bottom: 0px;
    right: 0px;
  }

  .rightImg {
    height: 794px;
    width: 916px;
  }
  .bc2Heading {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 275;
    font-size: 1.2rem !important;
    line-height: 1.6rem !important;
    color: #ffffff;
  }

  .requestButton {
    min-width: 240px !important;
    min-height: 82px !important;
    margin-left: 42vw;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 22px !important;
    line-height: 31px !important;
    text-align: center;
    color: #ffffff;
    background: #fd6317;
    border-radius: 10px;
    position: absolute;
    top: 200px !important;
  }

  .bc2Heading2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 1.3rem !important;
    line-height: 1.7rem !important;
    color: #ffffff;
  }


  .features {
    margin-top: 300px !important;
    background-color: #16191e;
  }

  .businessContent {
    margin-top: 150px !important;
  }
}
