.rootFeatures {
    background-color: #16191E;
    padding-left: 10vw;
    padding-right: 10vw;
}

.rowFeatures {
  display: flex;
  flex-direction: row;
}

.columnFeatures {
  display: flex;
  flex-direction: column;
}

.header {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.desc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #8e97a0;
}

.leftMargin {
  margin-left: 10vw;
}

.topMargin {
  margin-top: 0px;
}

@media only screen and (max-width: 600px) { 
  .rowFeatures {
    display: flex;
    flex-direction: column;
  }
  .leftMargin {
    margin-left: 0vw !important;
  }
  .topMargin {
    margin-top: 50px;
  }
  .header { 
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1700px) {
  html {
    font-size: 36px;
  }

  .header {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 1.1rem !important;
    line-height: 1.4rem !important;
    color: #ffffff;
  }
}