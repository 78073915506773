.achCont {
  width: 85vw;
  height: "auto";
  background: #1f2228;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(44px);
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-left: 3.125vw;
  padding-right: 3.125vw;
  padding-top: 5vw;
  padding-bottom: 5vw;
  margin-top: 5vh;
}

.itemCont {
  min-width: 283px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 2vw;
  padding-right: 2vw;
}
.divider {
  border-right: 1px solid #464646;
  height: "100%";
}

.itemHeader {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 275;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-top: 2vh;
}
.itemDesc {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  margin-top: 2vh;
}
.itemDesc2 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff;
  margin-top: -2vh;
}
.itemBy {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #fd6317;
  margin-top: 1vh;
}

@media only screen and (max-width: 600px) {
  .achCont {
    width: 85vw;
    height: "auto";
    background: #1f2228;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(44px);
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5vw;
    padding-bottom: 5vw;
    margin-top: 20px;
  }

  .divider {
    border-right: none;
    border-bottom: 1px solid #464646;
    height: "100%";
    margin-bottom: 10px;
  }

  .itemCont {
    min-width: 283px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1100px) and (orientation: landscape) {
  .achCont {
    width: 85vw;
    height: "auto";
    background: #1f2228;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(44px);
    border-radius: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding-left: 3.125vw;
    padding-right: 3.125vw;
    padding-top: 5vw;
    padding-bottom: 5vw;
    margin-top: 5vh;
  }

  .itemCont {
    min-width: 183px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 2vw;
    padding-right: 2vw;
  }
  .divider {
    border-right: 1px solid #464646;
    height: "100%";
  }

  .itemHeader {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 275;
    font-size: 13px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-top: 2vh;
  }
  .itemDesc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-top: 2vh;
  }
  .itemDesc2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    margin-top: -2vh;
  }
  .itemBy {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #fd6317;
    margin-top: 1vh;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1100px) and (orientation: portrait) {
  .achCont {
    width: 85vw;
    height: "auto";
    background: #1f2228;
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(44px);
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5vw;
    padding-bottom: 5vw;
    margin-top: 20px;
  }

  .divider {
    border-right: none;
    border-bottom: 1px solid #464646;
    height: "100%";
    margin-bottom: 10px;
  }

  .itemCont {
    min-width: 283px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 1900px) { 
  html { font-size: 36px; }
  .itemHeader {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 375;
    font-size: 0.8rem !important;
    line-height: 1.3rem !important;
    text-align: center;
    color: #ffffff;
    margin-top: 2vh;
  }
  .itemDesc {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem !important;
    line-height: 1.5rem !important;
    text-align: center;
    color: #ffffff;
    margin-top: 2vh;
  }
  .itemDesc2 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem !important;
    line-height: 1.5rem !important;
    text-align: center;
    color: #ffffff;
    margin-top: -2vh;
  }
  .itemBy {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem !important;
    line-height: 1.8rem !important;
    text-align: center;
    color: #fd6317;
    margin-top: 1vh;
  }
}
