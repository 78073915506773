* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.logo {
  width: 110px;
  height: 110px;
}

.logotext {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  color: #ffffff;
  margin-top: 5px;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #16191e;
  height: 104px;
  left: 0px;
  top: 0px;
  margin-left: 6.7vw;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin-right: 6vw;
  width: 800px;
  transition: all 0.3s ease;
}
.nav-links.sticky {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 100%;
  background-color: #16191e;
}

.nav-links > a:hover {
  font-family: "Poppins";
  text-decoration: none;
  font-style: normal;
  font-weight: regular;
  font-size: 14px;
  line-height: 21px;
  color: #fd6317;
  padding: 5px 10px;
  margin: 0 10px;
  cursor: pointer;
}

.nav-links a:active {
  font-family: "Poppins";
  text-decoration: none;
  font-style: normal;
  font-weight: regular;
  font-size: 14px;
  line-height: 21px;
  color: #fd6317;
  padding: 5px 10px;
  margin: 0 10px;
  cursor: pointer;
}

.home,
.whywme,
.aboutus,
.advisors,
.business {
  font-family: "Poppins";
  text-decoration: none;
  font-style: normal;
  font-weight: regular;
  font-size: 14px;
  line-height: 21px;
  color: white;
  padding: 5px 10px;
  margin: 0 10px;
  cursor: pointer;
}

.activeNavLink {
  font-family: "Poppins";
  text-decoration: none;
  font-style: normal;
  font-weight: regular;
  font-size: 14px;
  line-height: 21px;
  color: #fd6317;
  padding: 5px 10px;
  margin: 0 10px;
  cursor: pointer;
}

.respToggle {
  display: none;
}
.respOffcanvas {
  display: none;
}

@media only screen and (max-width: 600px) {
  .nav-links {
    display: none;
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #16191e;
    height: "auto";
    margin-right: 0vw;
    margin-left: 0vw;
  }

  .respToggle {
    display: inline-flex;
  }

  .respOffcanvas {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 90vh;
    width: 100vw;
  }

  .respOffcanvas p {
    margin-top: 5vh;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
  }

  .logo {
    width: 80px;
    height: 80px;
  }

  .logotext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    margin-top: 5px;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1100px) and (orientation: landscape) {
  .logo {
    width: 90px;
    height: 90px;
  }

  .logotext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 36px;
    color: #ffffff;
    margin-top: 5px;
  }

  .navbar {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: #16191e;
    height: 104px;
    left: 0px;
    top: 0px;
    margin-left: 3.7vw;
  }
  .nav-links {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin-right: 6vw;
    width: 600px;
  }

  .home,
  .whywme,
  .aboutus,
  .advisors,
  .business {
    font-family: "Poppins";
    text-decoration: none;
    font-style: normal;
    font-weight: regular;
    font-size: 12px;
    line-height: 18px;
    color: white;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
  }

  .activeNavLink {
    font-family: "Poppins";
    text-decoration: none;
    font-style: normal;
    font-weight: regular;
    font-size: 12px;
    line-height: 18px;
    color: #fd6317;
    padding: 5px 10px;
    margin: 0 5px;
    cursor: pointer;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1100px) and (orientation: portrait) {
  .nav-links {
    display: none;
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #16191e;
    height: "auto";
    margin-right: 0vw;
    margin-left: 0vw;
  }

  .respToggle {
    display: inline-flex;
  }

  .respOffcanvas {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 90vh;
    width: 100vw;
  }

  .respOffcanvas p {
    margin-top: 5vh;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #ffffff;
  }

  .logo {
    width: 80px;
    height: 80px;
  }

  .logotext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    margin-top: 5px;
  }
}

@media only screen and (min-width: 1700px) {
  html {
    font-size: 36px;
  }

  .logo {
    width: 4.5rem;
    height: 4.5rem;
  }

  .logotext {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: #ffffff;
    margin-top: 5px;
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #16191e;
    height: 104px;
    left: 0px;
    top: 0px;
    margin-left: 6.7vw;
  }

  .nav-links {
    display: flex;
    justify-content: flex-end;
    list-style: none;
    margin-right: 6vw;
    width: 30rem;
  }

  .nav-links > a:hover {
    font-family: "Poppins";
    text-decoration: none;
    font-style: normal;
    font-weight: regular;
    font-size: 0.6rem;
    line-height: 1rem;
    color: #fd6317;
    padding: 5px 10px;
    margin: 0 10px;
    cursor: pointer;
  }

  .nav-links a:active {
    font-family: "Poppins";
    text-decoration: none;
    font-style: normal;
    font-weight: regular;
    font-size: 0.6rem;
    line-height: 1rem;
    color: #fd6317;
    padding: 5px 10px;
    margin: 0 10px;
    cursor: pointer;
  }

  .home,
  .whywme,
  .aboutus,
  .advisors,
  .business {
    font-family: "Poppins";
    text-decoration: none;
    font-style: normal;
    font-weight: regular;
    font-size: 0.6rem;
    line-height: 1rem;
    color: white;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
  }

  .activeNavLink {
    font-family: "Poppins";
    text-decoration: none;
    font-style: normal;
    font-weight: regular;
    font-size: 0.6rem;
    line-height: 1rem;
    color: #fd6317;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
  }
}
