.aboutUsDiv {
  box-sizing: border-box;
  background: #16191e;
  background-size: contain;
  margin: 0px;
  height: auto;
  width: 100%;
}

.aboutUsContent {
  display: flex;
  height: 100%;
  margin-left: 8vw;
  margin-top: 75px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #ffffff;
}

.aboutUsLine {
  display: flex;
  height: 100%;
  margin-left: 8vw;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  color: #ffffff;
}

.colorOrange {
  color: #fd6317;
}

.aboutContainerTop {
  width: 100%;
  height: auto;
  background-color: #16191e;
  margin-top: 7.5vh;
  display: flex;
  flex-direction: row;
  border: #16191e;
}

.aboutContainerTop .row {
  margin-left: -45px !important;
}

.cardDiv {
  background: #16191e;
  margin-bottom: 10px !important;
}

.cardDiv.card {
  background: #16191e;
  margin-bottom: 10px !important;
}

.cardItem {
  width: 278px;
  height: 276px;
  background-color: #16191e;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 11px;
}

.cardDetails {
  background: #16191e;
  width: 100%;
}

.card-img {
  max-width: 256px;
  max-height: 209px !important;
}

.cardDetails-img {
  max-width: 256px;
  max-height: 209px !important;
}

.cardName {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #ffffff !important;
}

.cardDesignation {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #fd6317;
}

.cardDescription {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 275;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.contactUsContainer {
  margin-left: 5.5vw;
  margin-top: 250px;
  margin-bottom: 142px;
}

@media only screen and (max-width: 600px) {
  .displayWeb {
    flex-direction: column !important;
    align-items: center !important;
  }
  .aboutUsContent {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 35px;
  }
  .aboutUsLine {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1100px) and (orientation: landscape) {
}

@media only screen and (min-device-width: 600px) and (max-device-width: 1100px) and (orientation: portrait) {
  .displayWeb {
    flex-direction: column !important;
    align-items: center !important;
  }
  .aboutUsContent {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 35px;
  }
  .aboutUsLine {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
  }
}

@media only screen and (min-width: 1700px) {
  html {
    font-size: 36px;
  }

  .aboutUsContent {
    display: flex;
    height: 100%;
    margin-left: 8vw;
    margin-top: 175px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 1.2rem !important;
    line-height: 1.8rem !important;
    color: #ffffff;
  }

  .aboutUsLine {
    display: flex;
    height: 100%;
    margin-left: 8vw;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem !important;
    line-height: 1.8rem !important;
    color: #ffffff;
  }

  .colorOrange {
    color: #fd6317;
    font-size: 1.25rem !important;
    line-height: 1.9rem !important;
  }

  .displayWeb > .col-xl-3 {
    /* min-width: 13rem !important; */
    min-height: 500px !important;
  }

  .cardDiv {
    background: #16191e;
    margin-bottom: 40px !important;
    /* min-width: 6rem !important; */
    margin-left: 40px !important;
  }

  .cardDiv.card {
    background: #16191e;
    margin-bottom: 10px !important;
    /* min-width: 8rem !important; */
  }

  .cardItem {
    width: 278px;
    height: 276px;
    background-color: #16191e;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 11px;
    /* min-width: 13rem !important; */
  }

  .cardDetails {
    background: #16191e;
    width: 100%;
  }

  .card-img {
    max-width: 256px;
    max-height: 209px !important;
  }

  .cardDetails-img {
    min-width: 356px !important;
    min-height: 309px !important;
  }

  .cardName {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 0.7rem !important;
    line-height: 1.2rem !important;
    text-align: center;
    color: #ffffff !important;
  }

  .cardDesignation {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem !important;
    line-height: 1.2rem !important;
    text-align: center;
    color: #fd6317;
  }

  .cardDescription {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 275;
    font-size: 0.7rem !important;
    line-height: 1.2rem !important;
    text-align: center;
    color: #ffffff;
  }
}
