.scrollTopCont > .scroll-to-top {
    background-color: #fd6317;
    border-radius: 3 !important;
    box-shadow: none !important;
}

@media only screen and (min-width: 1700px) {
    html {
      font-size: 36px;
    }

    .scrollTopCont > .scroll-to-top {
        background-color: #fd6317;
        border-radius: 3 !important;
        box-shadow: none !important;
        min-width: 2rem !important;
        min-height: 2rem !important;
    }
}