.packagesContainerBottom {
    max-width: 100vw;
    height: auto;
    /* background-color: #1F2228; */
    border-radius: 5px;
    /* margin-top: 1.5vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}

.packageType {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 36px;
    /* identical to box height */
    text-align: center;
    color: #FFFFFF;
    margin-top: 15px;
    margin-bottom: 10px;
}

.packageAmount {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #8E97A0;
    margin-top: 15px;
    margin-bottom: 10px;
}

.carouselContainerHome {
    max-width: 100vw !important;
    /* overflow: hidden !important; */
    margin-bottom: 5px !important;
    /* margin-left: 14vw; */
    /* margin-left: auto; */
}

.itemContainerHome {
    min-width: 763px;
    /* margin-left: 10vw; */
}

.sliderHome {
    max-width: fit-content;
    padding-right: 5%;
}

.carouselParentHome {
    min-width: 100vw;
    max-width: 100vw !important;
    overflow: hidden !important;
    position: relative;
    height: 750px;
    padding-left: calc(43.5vw - 380px);
    padding-right: 5% !important;
}

.dotListHome {
    position: absolute;
    left: 30vw;
    bottom: -20px;
    margin-top: -10px !important;
    max-width: 100vw !important;
}

.mobileCarousel {
    display: none;
}

@media only screen and (max-width: 600px) {
    .carouselContainerHome {
        display: none !important;
    }

    .bottomContainer {
        display: none;
    }

    .packageType {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        text-align: center;
        color: #FFFFFF;
    }

    .packageAmount {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #8E97A0;
    }

    .mobileCarousel {
        display: block;
    }
}